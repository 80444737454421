<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <div class="header-content">
            <h2 class="content-header-title float-left pr-1 mb-0">
              {{ $route.meta.pageTitle }}
            </h2>
            <div
              v-if="$route.meta.breadcrumb"
              class="breadcrumb-wrapper"
            >
              <b-breadcrumb>
                <b-breadcrumb-item to="/">
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  v-for="item in $route.meta.breadcrumb"
                  :key="item.text"
                  :active="item.active"
                  :to="item.to"
                >
                  {{ item.text }}
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
            <div class="clearfix" />
          </div>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <b-col
      class="content-header-right text-md-right d-md-block d-none mb-1"
      md="3"
      cols="12"
    >
      <b-button
        class="d-flex align-items-center ml-auto"
        variant="primary"
        :disabled="importRunning ? true : false"
        @click="onImport"
      >
        <template v-if="importRunning">
          <feather-icon
            icon="LoaderIcon"
            size="18"
            class="mr-1"
          />
          Идет публикация изменений
        </template>
        <template v-else>
          <feather-icon
            icon="UploadCloudIcon"
            size="18"
            class="mr-1"
          />
          Опубликовать изменения
        </template>
      </b-button>
      <!--      <b-dropdown-->
      <!--        variant="link"-->
      <!--        no-caret-->
      <!--        toggle-class="p-0"-->
      <!--        right-->
      <!--      >-->

      <!--        <template #button-content>-->
      <!--          <b-button-->
      <!--            v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
      <!--            variant="primary"-->
      <!--            class="btn-icon"-->
      <!--          >-->
      <!--            <feather-icon icon="SettingsIcon" />-->
      <!--          </b-button>-->
      <!--        </template>-->

      <!--        <b-dropdown-item :to="{ name: 'apps-todo' }">-->
      <!--          <feather-icon-->
      <!--            icon="CheckSquareIcon"-->
      <!--            size="16"-->
      <!--          />-->
      <!--          <span class="align-middle ml-50">Todo</span>-->
      <!--        </b-dropdown-item>-->

      <!--        <b-dropdown-item :to="{ name: 'apps-chat' }">-->
      <!--          <feather-icon-->
      <!--            icon="MessageSquareIcon"-->
      <!--            size="16"-->
      <!--          />-->
      <!--          <span class="align-middle ml-50">Chat</span>-->
      <!--        </b-dropdown-item>-->

      <!--        <b-dropdown-item :to="{ name: 'apps-email' }">-->
      <!--          <feather-icon-->
      <!--            icon="MailIcon"-->
      <!--            size="16"-->
      <!--          />-->
      <!--          <span class="align-middle ml-50">Email</span>-->
      <!--        </b-dropdown-item>-->

      <!--        <b-dropdown-item :to="{ name: 'apps-calendar' }">-->
      <!--          <feather-icon-->
      <!--            icon="CalendarIcon"-->
      <!--            size="16"-->
      <!--          />-->
      <!--          <span class="align-middle ml-50">Calendar</span>-->
      <!--        </b-dropdown-item>-->
      <!--      </b-dropdown>-->
    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Site from '@/api/http/models/site/site/Site'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      importRunning: false,
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  mounted() {
    if (this.currentSite.import_running) {
      this.importRunning = true
    } else {
      this.importRunning = false
    }
  },
  methods: {
    async onImport() {
      const responseImport = await Site.import(this.currentSite.slug)

      if (responseImport.status === 'success') {
        this.importRunning = true
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Публикация данных запущена',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      } else if (responseImport.errors) {
        this.mixinErrorsParse(responseImport.errors)
      }
    },
  },
}
</script>
