<template>
  <p class="clearfix mb-0">
    <span class="float-md-right d-none d-md-block">
      © {{ new Date().getFullYear() }}, Все права защищены
    </span>
  </p>
</template>

<script>
export default {

}
</script>
