export default [
  {
    title: 'Панель управления',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    header: 'Администрирование',
    requires: {
      isAdmin: true,
    },
  },
  {
    id: 'admin_brands',
    title: 'Бренды/Модели',
    route: 'admin_brands_and_models',
    icon: 'CircleIcon',
    requires: {
      isAdmin: true,
    },
  },
  {
    id: 'admin_services',
    title: 'Услуги',
    route: 'admin_services',
    icon: 'CircleIcon',
    requires: {
      isAdmin: true,
    },
  },
  {
    title: 'Шаблоны сбора отзывов',
    route: 'admin_feedback_templates',
    icon: 'SettingsIcon',
    id: 'admin_feedback_templates',
    requires: {
      isAdmin: true,
    },
  },
  {
    header: 'Контент',
  },
  {
    title: 'Услуги',
    route: 'pages',
    icon: 'ToolIcon',
    id: 'pages',
  },
  {
    title: 'Акции',
    route: 'promos',
    icon: 'PercentIcon',
    id: 'promos',
  },
  {
    title: 'Блог',
    route: 'blog',
    icon: 'LayersIcon',
    id: 'blog',
  },
  {
    title: 'Отзывы',
    route: 'reviews',
    icon: 'StarIcon',
    id: 'reviews',
  },
  {
    title: 'Контакты',
    route: 'contacts',
    icon: 'MapPinIcon',
    id: 'contacts',
  },
  {
    title: 'Сотрудники',
    route: 'employees',
    icon: 'UsersIcon',
    id: 'employees',
  },
  {
    title: 'Вакансии',
    route: 'vacancies',
    icon: 'UserPlusIcon',
    id: 'vacancies',
  },
  {
    title: 'Пользовательские',
    route: 'custom-page',
    icon: 'SettingsIcon',
    id: 'custom-page',
  },
  {
    title: 'Счетчик услуг',
    route: 'work-counter',
    icon: 'BarChart2Icon',
    id: 'work-counter',
  },
  {
    title: 'Поиск',
    route: 'search',
    icon: 'SearchIcon',
    id: 'search',
  },
  {
    header: 'Отзывы',
  },
  {
    title: 'Мастера',
    route: 'feedback_masters',
    icon: 'AlignLeftIcon',
    id: 'feedback_masters',
  },
  {
    title: 'Статистика',
    route: 'feedback_stats',
    icon: 'AlignLeftIcon',
    id: 'feedback_stats',
  },
  {
    title: 'Настройки',
    route: 'feedback_settings',
    icon: 'SettingsIcon',
    id: 'feedback_settings',
  },
  {
    header: 'Клиенты',
  },
  {
    title: 'Заявки',
    route: 'requests',
    icon: 'AlignLeftIcon',
    id: 'requests',
  },
  {
    title: 'Аналитика',
    route: 'analytics',
    icon: 'ActivityIcon',
    id: 'analytics',
  },
  {
    header: 'Настройки сайта',
  },
  {
    id: 'maintenance',
    title: 'Калькулятор ТО',
    route: 'maintenance',
    icon: 'UploadIcon',
  },
  {
    title: 'Шаблоны',
    route: 'templates',
    icon: 'PaperclipIcon',
    id: 'templates',
  },
  {
    title: 'Бренды/Модели',
    route: 'site_brands_and_models',
    icon: 'FolderMinusIcon',
    id: 'site_brands',
  },
  {
    title: 'Выбор услуг',
    route: 'site_services',
    icon: 'ToolIcon',
    id: 'site_services',
  },
  {
    title: 'Общие настройки',
    route: 'settings',
    icon: 'SettingsIcon',
    id: 'settings',
  },
  {
    title: 'Интеграции',
    route: 'tokens',
    icon: 'Share2Icon',
  },
  {
    title: 'Вебхуки',
    route: 'webhooks',
    icon: 'RepeatIcon',
    id: 'webhooks',
  },
  {
    header: 'Дополнительно',
  },
  {
    title: 'FAQ',
    href: 'https://zeroheight.com/9974e9ea3/p/17da01---jaky',
    icon: 'InfoIcon',
  },
]
